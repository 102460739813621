import React, { useState } from 'react'
import SelectContext from './context';

export const SelectProvider = ({ children }) => {
	const [value, setValue] = useState('');

	return (
		<SelectContext.Provider value={{ value, setValue }}>
			{children}
		</SelectContext.Provider>
	)
}	