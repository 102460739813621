import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { useAuthContext } from '../../../providers/auth-user';

function AdminRoute({ redirectPath }) {
    // const { user } = useAuthContext(); // {user, setUser}
    const user = {
        role: 'superadmin',
        isLoggedIn: true
    }
    if (user.role === 'superadmin' && user.isLoggedIn) {
        return (
            <div>
                <Outlet />
            </div>
        )
    }
    return (
        <Navigate to={redirectPath} replace />
    )
}

export default AdminRoute