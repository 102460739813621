import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../../providers/auth-user';

export const RedirectToAuth = () => {
    return <Navigate to='/auth/login' replace />
}

function AuthRoute({ redirectPath }) {
    const { user } = useAuthContext();
    if (user.role === undefined && !user.isLoggedIn) {
        return (
            <div>
                <Outlet />
            </div>
        )


    }
    return (
        <Navigate to={redirectPath} replace />
    )
}

export default AuthRoute