import React from 'react'
import { Link } from 'react-router-dom'

function About() {
    return (
        <div>
            <h1>
                About
            </h1>
            <p>descriere about</p>
            <Link to={'/'}>
                go Home
            </Link>
        </div>
    )
}

export default About