import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllUsers from '../../views/admin/all-users/AllUsers'
import Dashboard from '../../views/admin/dashboard/Dashboard'
import Login from '../../views/auth/login/Login'
import Register from '../../views/auth/register/Register'
import About from '../../views/guest/about/About'
import Home from '../../views/guest/home/Home'
import NotFound from '../../views/guest/not-found/NotFound'
import MyProfile from '../../views/user/my-profile/MyProfile'
import AdminRoute from './admin/AdminRoute'
import AuthRoute, { RedirectToAuth } from './auth/AuthRoute'
import UserRoute, { RedirectToUser } from './user/UserRouting'
import SelectData from '../../views/admin/select-data/SelectData'

const AppRouting = () => {
    return (
        <Routes>
            <Route path='/auth' element={<AuthRoute redirectPath='/admin' />}>
                <Route path='/auth' element={<RedirectToAuth />} />
                <Route path='/auth/login' element={<Login />} />
                <Route path='/auth/register' element={<Register />} />
            </Route>
            <Route path='/admin' element={<AdminRoute redirectPath='/user' />}>
                <Route path='/admin' element={<Dashboard />} />
                <Route path='/admin/all-users' element={<AllUsers />} />
                <Route path='/admin/select-data' element={<SelectData/>}/>
            </Route>
            <Route path='/user' element={<UserRoute redirectPath='/' />}>
                <Route path='/user' element={<RedirectToUser />} />
                <Route path='/user/my-profile' element={<MyProfile />} />
            </Route>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRouting