import { useState } from 'react';
import CountingContext from './context';

const CountingProvider = ({ children }) => {
	const [count, setCount] = useState(0);
	const handleValues = () => {
		// some complex logic
	}

	return (
		<CountingContext.Provider value={{ count, setCount, handleValues }}>
			{children}
		</CountingContext.Provider>
	);
};

export default CountingProvider;