import React from "react";
import "./App.css";
import { Footer, Header } from "./components";
import AppRouting from "./utils/routing/AppRouting";
import { AuthProvider } from './providers/auth-user';

const App = () => {
  return (
    <>
      <AuthProvider>
        <Header />
        <AppRouting />
      </AuthProvider>
      <Footer />
    </>
  );
};

export default App;
