import React from 'react'
import { useCountingContext } from '../../../providers/counting/context';

function MyProfile() {
    const { count } = useCountingContext();
    return (
        <div>
            <h1>MyProfile</h1>
            <p>{count}</p>
        </div>
    )
}

export default MyProfile