import { createContext, useContext } from 'react';

const CountingContext = createContext({
	count: 0,
	setCount: () => {},
	handleValues: () => {},
});

export const useCountingContext = () => useContext(CountingContext);

export default CountingContext;

// 1. create context
// 2. create provider
// 3. create consumer