import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
    return (
        <div>
            <h1>Home</h1>
            <p>descriere home</p>
            <Link to={'/about'}>
                go About
            </Link>
        </div>
    )
}

export default Home