import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../../providers/auth-user';
export const RedirectToUser = () => {
    return <Navigate to='/user/my-profile' replace />
}

function UserRoute({ redirectPath }) {
    const { user } = useAuthContext();
    if ((user.role === 'user' || user.role === 'superadmin') && user.isLoggedIn) {
        return (
            <div>
                <Outlet />
            </div>
        )
    }
    return (
        <Navigate to={redirectPath} replace />
    )
}

export default UserRoute