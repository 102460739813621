import React, { useMemo, useState } from 'react'
import AuthContext from './context';

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({});

	const anotherValue = useMemo(() => {
		return {
			user,
			setUser
		}
	}, [user])

	return (
	<AuthContext.Provider value={anotherValue}>
	  {children}
	</AuthContext.Provider>
  )
}
