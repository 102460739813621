import React from 'react'

export default function NotFound() {
    return (
        <div>
            <h1>
                Sorry :( N-am gasit boss
            </h1>
        </div>
    )
}
