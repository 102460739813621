import React, { useCallback, useEffect } from "react";
import { default as MyCard } from "../../../components/card/component";
import { myRequest } from "../../../utils/axios/config";
import "./Dashboard.css";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Slider, TextField } from '@mui/material';
import useDebounce from '../../../utils/hooks/useDebounce';

function Dashboard() {
    const [selectedCategory, setSelectedCategory] = React.useState("All");
    const [selectedRange, setSelectedRange] = React.useState([0, 1000]);
    const [categories, setCategories] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const debouncedValue = useDebounce(search);
    
    const handleProducts = async () => {
        try {
            const url = selectedCategory === "All" ? `/products` : `/products/category/${selectedCategory}`
            const response = await myRequest.get(url);
            const data = await response.data;
            const filteredData = data.filter((product) => product.price >= selectedRange[0] && product.price <= selectedRange[1] && (product.title.toLowerCase().includes(debouncedValue.toLowerCase()) || product.description.toLowerCase().includes(debouncedValue.toLowerCase())));
            setProducts(filteredData);
        } catch (error) {
            console.log("error from admin", error);
        }
    };

    const handleCategories = React.useCallback(async () => {
        try {
            const response = await myRequest.get(`/products/categories`);
            const data = await response.data;
            setCategories(data);
        } catch (error) {
            console.log("error from admin", error);
        }
    }, [])

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    }

    const handleRangeChange = (event, newValue) => {
        setSelectedRange(newValue);
    }

    const handleResetFilters = useCallback(() => {
        setSelectedCategory('All');
        setSelectedRange([0, 1000]);
        setSearch('');
    }, [])

    useEffect(() => {
        handleCategories();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        handleProducts();
        // eslint-disable-next-line
    }, [selectedCategory, JSON.stringify(selectedRange), debouncedValue])

 
    return (
        <div>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column'}}>

            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                      value={selectedCategory}
                    label="Categories"
                  onChange={handleCategoryChange} 
                >
                    <MenuItem value={"All"}>All</MenuItem>
                    {categories.map((category) => (
                        <MenuItem
                            key={category}
                            value={category}
                        >
                            {category}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <Slider
                    getAriaLabel={() => 'Price range'}
                    value={selectedRange}
                    onChange={handleRangeChange}
                    valueLabelDisplay="auto"
                    max={1000}
                    min={0}
                    // getAriaValueText={valuetext}
                />
            </FormControl>
            <FormControl>
                <TextField 
                    id="outlined-basic" 
                    label="Search Product" variant="outlined" placeholder='Search by name or description' value={search} onChange={(e) => setSearch(e?.target?.value)}/>
            </FormControl>
            <Button onClick={() => handleResetFilters()}>Reset filters</Button>
            </Box>

            <ul className="d-flex">
                {products.map((product) => (
                    <li key={product.id}>
                        <MyCard
                            title={product.title}
                            description={product.description}
                            image={product.image}
                            price={product.price}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Dashboard;