import axios from 'axios';

const baseUrl = 'https://fakestoreapi.com';
const localUrl = 'http://localhost:5050';

export const myRequest = axios.create({
    baseURL:  `${baseUrl}`,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
})

export const adminRequest = axios.create({
    baseURL:  `${localUrl}/admin`,
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
})