import { createContext, useContext } from 'react';

const SelectContext = createContext({
	value: '',
	setValue: () => { },
});


export const useSelectContext = () => useContext(SelectContext);

export default SelectContext;