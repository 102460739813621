import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelectContext } from '../../providers/dropdown/context';

export default function MyDropdown() {
const {value, setValue } = useSelectContext();
	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<Box sx={{ minWidth: 120 }}>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">Age</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={value}
					label="Age"
					onChange={handleChange}
				>
					<MenuItem value={'Ten'}>Ten</MenuItem>
					<MenuItem value={'Twenty'}>Twenty</MenuItem>
					<MenuItem value={'Thirty'}>Thirty</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
}