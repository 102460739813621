// rfce
import { Button } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useAuthContext } from '../../providers/auth-user'

function Header() {
    // const [visibleButton, setVisibleButton] = useState(false)
    const { user } = useAuthContext();
    return (
        <header>
            {/* <Button onClick={() => setVisibleButton((prev) => !prev)} size='medium' variant='outlined' sx={{ backgroundColor: '#cecece' }} >
                Header
            </Button> */}
            <NavLink to={'/auth/login'}>
                <Button>
                    Login
                </Button>
            </NavLink>
            <NavLink to={'/auth/register'}>
                <Button>
                    Register
                </Button>
            </NavLink>
            <NavLink to={'/user/my-profile'}>
                <Button>
                    My Profile
                </Button>
            </NavLink>
            <NavLink to={'/admin'}>
                <Button>
                    Dashboard
                </Button>
            </NavLink>
            <NavLink to={'/admin/select-data'}>
                <Button>
                    Select data
                </Button>
            </NavLink>
            <Typography>
                    {user?.firstName ? `${user?.firstName} ${user?.lastName}` : 'Guest'}
            </Typography>
            
        </header>
    )
}

export default React.memo(Header);